// import Table from 'react-bootstrap/Table';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Image from 'assets/img/avatars/avatar3.png';
import Button from 'react-bootstrap/Button';
import { Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Tables = () => {
  return (
    <div>

      <div className=" mt-10 Hero-section ">
        <div className='add-Product-btn dark:text-white' > <Button className='product-btn'>Add Produst +</Button>
        
        </div>

        <Table responsive="md" className='table table-striped Hero-table dark:text-white bg-dark '>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>festival Name</Th>
              <Th>Product Name</Th>
              <Th>Product image</Th>
              <Th>status</Th>
         </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>1</Td>
              <Td>navratri</Td>
              <Td>ring</Td>
              <Td><img src={Image} style={{ width: '50px' }} /></Td>
              <Td><BootstrapSwitchButton className="festival-btn" checked={true} onstyle="success" offstyle="danger" style="border" /></Td>
            </Tr>
            <Tr>
              <Td>2</Td>
              <Td>navratri</Td>
              <Td>ring</Td>
              <Td><img src={Image} style={{ width: '50px' }} /></Td>
              <Td><BootstrapSwitchButton className="festival-btn" checked={true} onstyle="success" offstyle="danger" style="border" /></Td>
    
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>navratri</Td>
              <Td>ring</Td>
              <Td><img src={Image} style={{ width: '50px' }} /></Td>
              <Td><BootstrapSwitchButton className="festival-btn" checked={true} onstyle="success" offstyle="danger" style="border" /></Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Tables;
